<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">{{ $t("message.add_new_patient") }}</div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="quitEmpty()"
                >{{ $t("message.close") }}
              </el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="center__block_form" v-loading="loadingData">
      <el-form
        ref="form"
        :model="form"
        class="aticler_m"
        :rules="rules"
        size="small"
      >
        <el-row :gutter="25">
          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.surname')" prop="surname">
              <el-input
                v-model="form.surname"
                :placeholder="$t('message.surname')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.first_name')" prop="first_name">
              <el-input
                v-model="form.first_name"
                :placeholder="$t('message.first_name')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.patronymic')">
              <el-input
                v-model="form.patronymic"
                :placeholder="$t('message.patronymic')"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.code_id')">
              <el-input v-model="form.code" :disabled="true"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.phone')" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="+998 00 000 00 00"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.dob')" prop="born_date">
              <el-date-picker
                type="date"
                :placeholder="$t('message.dob')"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                v-model="form.born_date"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.gender')" prop="gender">
              <el-select v-model="form.gender" placeholder=".....">
                <el-option :label="$t('message.male')" value="man"></el-option>
                <el-option
                  :label="$t('message.female')"
                  value="woman"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.citizenship')">
              <citizenships
                v-model="form.citizenship_id"
                :citizenship_id="form.citizenship_id"
              ></citizenships>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.nationality')">
              <nationalities
                v-model="form.nationality_id"
                :nationality_id="form.nationality_id"
              ></nationalities>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.born_place')">
              <el-input
                v-model="form.born_place"
                placeholder="......"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.passport_number')">
              <el-input
                v-model="form.passport_number"
                placeholder="......"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
            <el-form-item :label="$t('message.date')">
              <el-date-picker
                type="date"
                :placeholder="$t('message.date')"
                v-model="form.given_date"
                style="width: 100%"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
                :picker-options="datePickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <!-- end col -->

          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-tabs type="border-card" class="mt-2">
              <el-tab-pane :label="$t('message.address_register')">
                <el-row :gutter="25">
                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item
                      :label="$t('message.country')"
                      prop="state_id"
                    >
                      <states
                        v-model="form.state_id"
                        :state_id="form.state_id"
                      ></states>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.city')">
                      <cities
                        v-model="form.city_id"
                        :state_id="form.state_id"
                      ></cities>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.region')">
                      <regions
                        v-model="form.region_id"
                        :city_id="form.city_id"
                      ></regions>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.street')">
                      <el-input
                        v-model="form.street"
                        :placeholder="$t('message.street')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.district')">
                      <el-input
                        v-model="form.district"
                        :placeholder="$t('message.district')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.house')">
                      <el-input
                        v-model="form.home"
                        :placeholder="$t('message.house')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.flat')">
                      <el-input
                        v-model="form.flat"
                        :placeholder="$t('message.flat')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end el-row -->
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.photo')">
                <el-row :gutter="25">
                  <el-col :span="12">
                    <el-upload
                      :action="base_url + 'patientImages/storeImage'"
                      :limit="1"
                      accept="image/*"
                      name="image"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                      :on-success="handleAvatarSuccess"
                      :headers="{
                        Authorization: 'Bearer ' + $store.getters.token,
                      }"
                    >
                      <img v-if="form.image" :src="form.image" class="avatar" />
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt />
                    </el-dialog>
                  </el-col>
                  <!-- end col -->
                </el-row>
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.add_document')">
                <el-row :gutter="25">
                  <el-col :span="8">
                    <el-upload
                      class="upload-demo flex1 ml-3"
                      :action="base_url + 'patientFiles/storeFile'"
                      :on-preview="handlePreview"
                      name="file"
                      :on-remove="handleRemove"
                      :on-success="handleAvatarSuccessFile"
                      :file-list="fileList"
                      list-type="picture"
                      :headers="{
                        Authorization: 'Bearer ' + $store.getters.token,
                      }"
                      :limit="1"
                    >
                      <el-button size="small" type="primary">{{
                        $t("message.add_document")
                      }}</el-button>
                    </el-upload>
                  </el-col>
                  <!-- end col -->
                </el-row>
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.documents')">
                <el-row :gutter="25">
                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.type_documents')">
                      <documentTypes
                        v-model="form.document_type_id"
                        :document_type_id="form.document_type_id"
                        size="mini"
                      ></documentTypes>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.series_and_number')">
                      <el-input
                        v-model="form.series_and_number"
                        :placeholder="$t('message.series_and_number')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.authority')">
                      <el-input
                        v-model="form.authority"
                        :placeholder="$t('message.authority')"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->

                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.date_of_issue')">
                      <el-date-picker
                        type="date"
                        :placeholder="$t('message.date_of_issue')"
                        v-model="form.date_of_issue"
                        :format="'dd.MM.yyyy'"
                        :value-format="'dd.MM.yyyy'"
                        style="width: 100%"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                </el-row>
                <!-- end el-row -->
              </el-tab-pane>
              <!-- end  el-tab-pane-->

              <el-tab-pane :label="$t('message.organization')">
                <el-row :gutter="25">
                  <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="6">
                    <el-form-item :label="$t('message.organization')">
                      <organizations
                        v-model="form.organization_id"
                        :organization_id="form.organization_id"
                        size="mini"
                      ></organizations>
                    </el-form-item>
                  </el-col>
                  <!-- end col -->
                </el-row>
              </el-tab-pane>
              <!-- end  el-tab-pane-->
            </el-tabs>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import cities from "@/components/selects/city";
import states from "@/components/selects/state";
import regions from "@/components/selects/region";
import citizenships from "@/components/selects/citizenship";
import nationalities from "@/components/selects/nationality";
import organizations from "@/components/selects/organization";
import documentTypes from "@/components/selects/documentType";

import drawer from "@/utils/mixins/drawer";
import drawerChild from "@/utils/mixins/drawer-child";

export default {
  mixins: [form, drawerChild, drawer],

  components: {
    cities,
    states,
    citizenships,
    nationalities,
    regions,
    organizations,
    documentTypes,
  },
  data() {
    return {
      number: "",
      loadingData: false,
      form: {
        given_date: new Date(),
      },
      datePickerOptions: {
        disabledDate(given_date) {
          return given_date.getTime() < Date.now() - 8.64e7;
        },
      },
      dialogImageUrl: "",
      dialogVisible: false,
      fileList: [],
    };
  },
  created() {
    this.form.given_date = new Date();
  },
  computed: {
    ...mapGetters({
      rules: "hospitalPatients/rules",
      model: "hospitalPatients/model",
      columns: "hospitalPatients/columns",
      lastId: "hospitalPatients/lastId",
      partnerDoctors: "partnerDoctor/list",
    }),
  },
  methods: {
    ...mapActions({
      getLastId: "hospitalPatients/getLastId",
      save: "hospitalPatients/store",
      empty: "hospitalPatients/empty",
    }),
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
      if (!this.lastId) {
        this.getLastId().then((res) => {
          this.form.code = this.getFormatted(res.data.last_id);
        });
      } else {
        this.form.code = this.getFormatted(this.lastId);
      }
      this.form.given_date = new Date();
    },
    getFormatted(num) {
      if (num < 10) {
        return "AA 000 000 00" + num;
      } else if (num >= 10 && num < 100) {
        return "AA 000 000 0" + num;
      } else if (num >= 100 && num < 1000) {
        return "AA 000 000 " + num;
      } else if (num >= 1000 && num < 10000) {
        return "AA 000 00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000 && num < 100000) {
        return "AA 000 0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000 && num < 1000000) {
        return "AA 000 " + new Intl.NumberFormat().format(num);
      } else if (num >= 1000000 && num < 10000000) {
        return "AA 00" + new Intl.NumberFormat().format(num);
      } else if (num >= 10000000 && num < 100000000) {
        return "AA 0" + new Intl.NumberFormat().format(num);
      } else if (num >= 100000000 && num < 1000000000) {
        return "AA " + new Intl.NumberFormat().format(num);
      } else if (num >= 1000000000 && num < 10000000000) {
        return "AB " + new Intl.NumberFormat().format(num);
      }
      return num;
    },
    afterClosed() {
      this.empty();
    },
    quitEmpty() {
      this.close();
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.form = {
                given_date: new Date(),
              };
              this.parent().listChanged();
              if (close == true) {
                this.close();
              }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.patient_image_id = res.data.patientImages.id;
    },
    handleAvatarSuccessFile(res, file) {
      this.form.patient_file_id = res.data.patientFiles.id;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
